.menu {
  box-sizing: border-box;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
}

.menu li {
  width: 50%;
  padding-right: 10px;
  display: list-item;
  unicode-bidi: isolate;
  box-sizing: border-box;
  margin-bottom: 3px;
}

.menu li a {
  font-weight: 500;
  color: #979797;
}

.menu li a:after {
  content: "";
  display: block;
  height: 2px;
  margin-top: 2px;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}

.menu li a:hover::after {
  width: 100%;
  background: #efc940;
}

.helpo_donation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.helpo_button {
  line-height: 1.7;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  padding: 14px 40px;
  border-radius: 50px;
  min-width: 160px;
  letter-spacing: 0.05em;
  transition: all 0.3s;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  margin-top: 4px;
  transform: translateY(-3px);
  box-shadow: none;
  background: #efc940;
  color: #333333;
  background-color: #e9ba13;
}
