@keyframes revertBorder {
  0% {
    border-color: #fff5d3;
  }
  100% {
    border-color: #efc940;
  }
}

.listWrap li {
  padding: 1.5rem 0rem;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
  text-transform: uppercase;
  font-style: normal;
  border-top: 3px solid #efc94000;
  transition: 0.6s all ease;
}

.listWrap a {
  color: #282a43 !important;
}

.listWrap li:hover {
  border-top: 3px solid #efc940;
}

.btnDonate {
  border: 1.5rem solid #efc940;
  transition: all 0.3s ease-in-out;
}

.btnDonate:hover {
  border: 1.5rem solid #fff5d3;
  animation: revertBorder 0.3s 0.3s forwards;
}

.newsInput,
.newsInput:autofill {
  width: 100%;
  background: #ffffff00;
  color: #ffffff;
  outline: none;
  resize: none;
  overflow: hidden;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  border: 2px solid var(--Grey-Heading, #25252500);
  border-bottom: 2px solid var(--Grey-Heading, #4d4b84);
  margin-bottom: 1.8rem;
  padding: 4px 0px;
}

.footerTextHover {
  cursor: pointer;
  border-bottom: 2px solid #efc94000;
  transition: all 0.2s linear;
}

.footerTextHover:hover {
  border-bottom: 2px solid #efc940;
}

@media (max-width: 900px) {
  .listWrap {
    visibility: hidden;
    width: 0 !important ;
  }

  .headerNse {
    visibility: hidden;
    height: 0;
    width: 0 !important ;
  }
}
