.accordion {
  width: 250px;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  background: #fff;
  box-shadow: 1px 1px 10px 4px #ddd;
}
.accordionHeader {
  display: flex;
  padding: 30px;
}
.accordionHeader > div {
  padding-top: 12px;
  margin: 0 10px;
}
.profileTittle {
  color: #198754;
  font-weight: 700;
}
.profileName {
  font-weight: 600;
  font-size: 14px;
}
.profileImg {
  border-radius: 50%;
}
.accordionBody ul {
  list-style: none;
  padding: 0;
}
.accordionBody ul li {
  color: #000;
  padding: 10px 40px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
}
.accordionBody ul li:hover,
.accordionBody ul li:active,
.accordionBody ul li:focus {
  background: rgb(244, 244, 244);
  color: #198754;
  border: none;
  transition: all 0.3s;
}
.accordionBody .activeLink li {
  background: rgb(244, 244, 244);
  color: #198754;
  transition: all 0.3s;
}
.accordionBottom {
  margin-top: 100px;
}
.accordionBottom ul {
  justify-content: space-around;
  list-style: none;
  display: flex;
  padding: 0 55px;
}
.accordionBottom ul li {
  cursor: pointer;
  margin: 0 6px;
}
.activeNavbar {
  position: absolute;
  left: -1000px;
}
.terms_list {
  list-style: none;
  font-size: 12px;
}
.terms_list li {
  padding: 5px 0;
  text-decoration: underline rgb(76, 76, 76);
  transition: all 0.2s;
  cursor: pointer;
}
.terms_list li:hover {
  scale: 1.02;
  color: rgb(45, 139, 130);
  text-decoration-color: rgb(45, 139, 130);
}
